<template>
    <el-dialog title="列显隐" :visible.sync="visible" width="50%" center @close="closeDialog">
        <!-- <div style="margin: 0 auto !important;width:100%"> -->
        <el-transfer v-model="value" filterable :titles="['显示', '隐藏']" :data="data" />
        <!-- </div> -->
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submit()">保存当前</el-button>
            <el-button @click="value = []">恢复默认</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    name: 'QualityDialog',
    components: {},
    props: {
        showDialog: {
            default: false,
        },
    },
    data() {
        return {
            visible: false,
            colData: [],
            data: [],
            value: [],
        };
    },
    created() {},
    mounted() {},
    methods: {
        init(data) {
            let colData = [],
                selectData = [];
            data.forEach((item) => {
                colData.push({
                    key: item.columnName,
                    label: item.columnName,
                });
                if (item.selected == 0) {
                    selectData.push(item.columnName);
                }
            });
            console.log('selectData', selectData);
            this.data = colData;
            this.value = selectData;
        },
        closeDialog() {
            this.$emit('update:showDialog', false);
        },
        submit() {
            this.$emit('submitShowOrHidden', this.value);
        },
    },
    watch: {
        showDialog() {
            this.visible = this.showDialog;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
    margin: 0 auto !important;
}
</style>
